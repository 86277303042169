import { useState } from "react"

export function useTracks() {
  const [currentTrack, setCurrentTrack] = useState(null)

  function setTrack(data) {
    setCurrentTrack(data)
  }
  return {
    currentTrack,
    setTrack,
  }
}
