import React from "react"
import { useField } from "formik"
import "./GenderSelection.scss"

function GenderSelection({ label, ...props }) {
  const [field, meta] = useField(props)
  return (
    <div
      className={`gender field${meta.touched && meta.error ? " error" : ""}`}
    >
      <h4 className={"gender__title"}>{label}</h4>
      <div className="gender__items">
        <div className="gender__item">
          <input
            type="radio"
            id={"male"}
            {...field}
            value={"male"}
            checked={field.value === "male"}
          />
          <label htmlFor="male">M</label>
        </div>
        <div className="gender__item">
          <input
            type="radio"
            id={"female"}
            {...field}
            value={"female"}
            checked={field.value === "female"}
          />
          <label htmlFor="female">V</label>
        </div>
        <div className="gender__item">
          <input
            type="radio"
            id={"other"}
            {...field}
            value={"other"}
            checked={field.value === "other"}
          />
          <label htmlFor="other">X</label>
        </div>
      </div>
      {meta.touched && meta.error ? (
        <div className="field--error">{meta.error}</div>
      ) : null}
    </div>
  )
}

export default GenderSelection
