import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Formik } from "formik"
import * as Yup from "yup"
import TextInput from "../Forms/TextInput"
import BtnForms from "../Buttons/BtnForms"
import useForm from "../../hooks/useForm"
import { useGuestApi } from "../../hooks/useApi"
import { useTranslation } from "react-i18next"
import { useRedirect } from "../../hooks/useRedirect"

const initialValues = {
  email: "",
}

function ForgotPassword({ onSubmitForm }) {
  const { forgotPassword } = useGuestApi()
  const [actions, setActions] = useState(null)
  const [error, setError] = useState(false)
  const { setRedirectRoute } = useRedirect()
  const { t } = useTranslation()
  const { submit, isLoading } = useForm({
    initialValues,
    onSubmit: forgotPassword,
    onSuccess: () => {
      onSubmitForm();
      setRedirectRoute('reset-password');

    },
    onFail: () => {
      setError(true)
    },
  })

  useEffect(() => {
    if (error && actions) {
      actions.setFieldError("email", t("forgotPassword.accountExists"))
    }
  }, [error])

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("validation.emailValid"))
      .required(t("validation.emailRequired")),
  })

  return (
    <div className="login">
      <div className="form__intro">
        <h3 className="form__intro--title">{t("forgotPassword.title")}</h3>
        <p className="form__intro--body">{t("forgotPassword.body")}</p>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={forgotPasswordSchema}
        onSubmit={(values, actions) => {
          setError(false)
          setActions(actions)
          submit(values)
        }}
      >
        {({ handleSubmit }) => (
          <form className="form" onSubmit={handleSubmit}>
            <TextInput
              name="email"
              type="email"
              placeholder={t("forms.email")}
            />
            <BtnForms large primary disabled={isLoading} loading={isLoading}>
              {t("forgotPassword.submit")}
            </BtnForms>
          </form>
        )}
      </Formik>
    </div>
  )
}

ForgotPassword.propTypes = {
  onSubmitForm: PropTypes.func,
}

export default ForgotPassword
