import React, { useEffect } from "react"

import iconCheck from "./img/icon-check.png"
import { useTranslation } from "react-i18next"

function RegisterSuccess() {
  const { t } = useTranslation()

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "play_nostalgie_register",
      login_registration_step: "account_created"
    })
  }, [])

  return (
    <div className="register-success">
      <div className="form__intro">
        <div className="form__intro--icon">
          <img src={iconCheck} alt="" />
        </div>
        <h3 className="form__intro--title">{t("register.titleSuccess")}</h3>
        <p className="form__intro--body">{t("register.bodySuccess")}</p>
      </div>
    </div>
  )
}

export default RegisterSuccess
