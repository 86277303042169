import React from "react"
import { useField } from "formik"
import "./TextInput.scss"
import InputMask from "react-input-mask"

function DateInput({ label, ...props }) {
  const [field, meta] = useField(props)
  return (
    <div className={`field${meta.touched && meta.error ? " error" : ""}`}>
      <InputMask
        mask="99/99/9999"
        maskChar={null}
        className="field--input"
        pattern="[0-9,\/]*"
        value={field.value ?? ""}
        name={field.name}
        onChange={field.onChange}
        onBlur={field.onBlur}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="field--error">{meta.error}</div>
      ) : null}
    </div>
  )
}

export default DateInput
