import React from "react"
import "./TabBar.scss"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

function TabBar(props) {
  const { activeTab, onClickLogin, onClickRegister } = props
  const { t } = useTranslation()

  return (
    <div className="auth__tabs">
      <button
        onClick={onClickLogin}
        className={`auth__tabs--item ${activeTab === "login" ? "active" : ""}`}
      >
        {t("tabBar.login")}
      </button>
      <button
        onClick={onClickRegister}
        className={`auth__tabs--item ${
          activeTab === "register" ? "active" : ""
        }`}
      >
        {t("tabBar.register")}
      </button>
    </div>
  )
}

TabBar.propTypes = {
  activeTab: PropTypes.string,
  onClickLogin: PropTypes.func,
  onClickRegister: PropTypes.func,
}

export default TabBar
