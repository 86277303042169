import { useContext } from "react"
import GuestApiContext from "src/context/GuestApiContext"
import AuthenticatedApiContext from "../context/AuthenticatedApiContext"

export function useGuestApi() {
  return useContext(GuestApiContext)
}

export function useAuthenticatedApi() {
  return useContext(AuthenticatedApiContext)
}
