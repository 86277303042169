import React from "react"
import { Formik, useField } from "formik"
import { useTranslation } from "react-i18next"
import { navigate } from "gatsby"
import cx from 'classnames'

import "./SearchBar.scss"
import Icon from "../Icon/Icon"

const SearchIcon = ({ onClick }) => (
  <button className="searchbar__button" onClick={onClick}>
    <Icon name="magnifier" className="nav__button-icon"/>
  </button>
)

const Field = ({ onClickSearch, children, ...props }) => {
  const [field] = useField(props)

  return (
    <>
      <input
        className="searchbar__input"
        type="text"
        id="search"
        {...field}
        {...props}
      />
      <SearchIcon onClick={onClickSearch} />
    </>
  )
}

const SearchBar = ({ className, theme }) => {
  const { t } = useTranslation()

  return (
    <div className={cx(`searchbar searchbar--${theme}`, className)}>
      <Formik
        initialValues={{ search: "" }}
        onSubmit={(values, { setSubmitting }) => {
          navigate(`/zoeken?query=${values.search}`)
          setSubmitting(false)
        }}
      >
        {({ handleSubmit }) => (
          <form className="form searchbar__form" onSubmit={handleSubmit}>
            <Field
              autoFocus={false}
              name="search"
              placeholder={t("searchBar.placeholder")}
              onClickSearch={handleSubmit}
            />
          </form>
        )}
      </Formik>
    </div>
  )
}

export default SearchBar
