import React from "react"
import PropTypes from "prop-types"
import { Formik } from "formik"
import BtnForms from "../Buttons/BtnForms"
import TextInput from "../Forms/TextInput"
import PasswordStrengthCheck from "../Forms/PasswordStrength"
import * as Yup from "yup"
import zxcvbn from "zxcvbn"
import { useTranslation } from "react-i18next"

function ResetPassword({ onSubmitForm }) {
  const { t } = useTranslation()
  const resetPwSchema = Yup.object().shape({
    old_password: Yup.string()
      .min(6, t("validation.passwordValid"))
      .required(t("validation.passwordRequired")),
    old_password_confirm: Yup.string()
      .min(6, t("validation.passwordValid"))
      .required(t("validation.passwordRequired"))
      .oneOf(
        [Yup.ref("old_password"), null],
        t("validation.passwordConfirmation")
      ),
    new_password: Yup.string()
      .min(6, t("validation.passwordValid"))
      .required(t("validation.passwordRequired"))
      .test("score", t("validation.passwordStrength"), value => {
        let val
        value === undefined ? (val = "") : (val = value)
        return zxcvbn(val).score === 4
      }),
  })
  return (
    <div className="choose-password">
      <div className="form__intro">
        <h3 className="form__intro--title">{t("resetPassword.title")}</h3>
        <p className="form__intro--body">{t("resetPassword.body")}</p>
      </div>
      <Formik
        initialValues={{
          old_password: "",
          old_password_confirm: "",
          new_password: "",
        }}
        validationSchema={resetPwSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmitForm()
          setSubmitting(false)
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form className="form" onSubmit={handleSubmit}>
            <TextInput
              name="old_password"
              type="password"
              placeholder={t("forms.oldPassword")}
            />
            <TextInput
              name="old_password_confirm"
              type="password"
              placeholder={t("forms.oldPasswordRepeat")}
            />
            <PasswordStrengthCheck
              name="new_password"
              type="password"
              placeholder={t("forms.newPassword")}
            />
            <BtnForms
              large
              primary
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              {t("resetPassword.submit")}
            </BtnForms>
          </form>
        )}
      </Formik>
    </div>
  )
}

ResetPassword.propTypes = {
  onSubmitForm: PropTypes.func,
}

export default ResetPassword
