import createPersistedState from "use-persisted-state"
export function useRedirect() {
  const [redirectToRoute, setRedirectToRoute] = createPersistedState("nostalgie.redirect.route")(null)

  function setRedirectRoute(route) {
    setRedirectToRoute(route)
  }

  function clearRedirectToRoute() {
    setRedirectToRoute(null)
  }


  return {
    redirectToRoute,
    setRedirectRoute,
    clearRedirectToRoute,
  }
}
