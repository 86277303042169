import React from "react"

import iconCheck from "./img/icon-check.png"
import { useTranslation } from "react-i18next"

function ForgotPasswordSuccess() {
  const { t } = useTranslation()
  return (
    <div className="reset-password-success">
      <div className="form__intro">
        <div className="form__intro--icon">
          <img src={iconCheck} alt="" />
        </div>
        <h3 className="form__intro--title">
          {t("forgotPasswordSuccess.title")}
        </h3>
      </div>
    </div>
  )
}

export default ForgotPasswordSuccess
