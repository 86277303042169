import React, { useState } from "react"
import { useField } from "formik"
import zxcvbn from "zxcvbn"
import "./PasswordStrength.scss"

function PasswordStrengthCheck({ label, ...props }) {
  const [field, meta, helpers] = useField(props)
  const [passwordScore, setPasswordScore] = useState(0)

  const { setValue } = helpers

  const checkScore = value => {
    value = value.target.value
    setValue(value)
    setPasswordScore(zxcvbn(value).score)
  }

  const scoreLabel = () => {
    if (passwordScore === 0) {
      return ""
    }
    if (passwordScore === 1) {
      return "Zwak"
    }
    if (passwordScore === 2) {
      return "Neutraal"
    }
    if (passwordScore === 3) {
      return "Sterk"
    }
    if (passwordScore === 4) {
      return "Zeer sterk"
    }
  }

  return (
    <div className={`field${meta.touched && meta.error ? " error" : ""}`}>
      <input
        className="field--input"
        {...field}
        {...props}
        onChange={value => checkScore(value)}
      />

      <div className={`field--score score--${passwordScore}`}>
        <div className={`field--score--indicators`}>
          <span />
          <span />
          <span />
          <span />
        </div>
        <div className="field--score--label">{scoreLabel()}</div>
      </div>

      {meta.touched && meta.error ? (
        <div className="field--error">{meta.error}</div>
      ) : null}
    </div>
  )
}

export default PasswordStrengthCheck
