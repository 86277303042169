import React from "react"
import { useField } from "formik"
import "./CheckBox.scss"

const CheckBox = ({ children, active, ...props }) => {
  const [field, meta] = useField(props)

  return (
    <div className="checkbox field">
      <input
        type="checkbox"
        className="checkbox--input"
        value={field.value}
        id={field.name}
        name={field.name}
        onChange={field.onChange}
        onBlur={field.onBlur}
        checked={field.value}
        {...props}
      />
      <label className="checkbox--label" htmlFor={field.name}>
        {children}
      </label>
    </div>
  )
}

export default CheckBox
