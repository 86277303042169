import React from "react"
import PropTypes from "prop-types"
import { Formik } from "formik"
import BtnForms from "../Buttons/BtnForms"
import zxcvbn from "zxcvbn"
import * as Yup from "yup"
import PasswordStrengthCheck from "../Forms/PasswordStrength"
import { useTranslation } from "react-i18next"

function ChoosePassword({ onSubmitForm, loading }) {
  const { t } = useTranslation()

  const logAnalytics = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "play_nostalgie_register",
      login_registration_step: "create_account",
    })
  }

  const chooosePwSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, t("validation.passwordValid"))
      .required(t("validation.passwordRequired"))
      .test("score", t("validation.passwordStrength"), value => {
        let val
        value === undefined ? (val = "") : (val = value)
        return zxcvbn(val).score >= 3
      }),
  })
  return (
    <div className="choose-password">
      <div className="form__intro">
        <h3 className="form__intro--title">{t("choosePassword.title")}</h3>
        <p className="form__intro--body">{t("choosePassword.body")}</p>
      </div>
      <Formik
        initialValues={{ password: "" }}
        validationSchema={chooosePwSchema}
        onSubmit={values => {
          logAnalytics()
          onSubmitForm(values)
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form className="form" onSubmit={handleSubmit}>
            <PasswordStrengthCheck
              name="password"
              type="password"
              placeholder={t("forms.newPassword")}
            />
            <BtnForms large primary disabled={isSubmitting} loading={loading}>
              {t("register.submit")}
            </BtnForms>
          </form>
        )}
      </Formik>
    </div>
  )
}

ChoosePassword.propTypes = {
  onSubmitForm: PropTypes.func,
}

export default ChoosePassword
