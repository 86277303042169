import React, { useEffect, useRef, useState } from "react"
import Modal from "../Modal/Modal"
import "./Auth.scss"
import Login from "./Login"
import Register from "./Register"
import ChoosePassword from "./ChoosePassword"
import RegisterSuccess from "./RegisterSuccess"
import ForgotPassword from "./ForgotPassword"
import ResetPassword from "./ResetPassword"
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock"
import { useRegister } from "../../hooks/useRegister"
import { useGuestApi } from "../../hooks/useApi"
import { createRegisterObject } from "../../helpers/Register"
import usePreferences from "../../hooks/usePreferences"
import ForgotPasswordSuccess from "./ForgotPasswordSuccess"
import RegisterFail from "./RegisterFail"
import { useVotes } from "../../hooks/useVotes"

function Auth({ visible, close, activeTab }) {
  const [activeStep, setActiveStep] = useState("login")
  const modal = useRef(null)
  const { register, convertToDateFormat, getMeByAccessToken, createVote, createQuestions } =
    useGuestApi()
  const { dateFormat } = usePreferences()
  const [loading, setLoading] = useState(false)
  const {
    votes,
    clearVotingVotes,
    clearLastVoting,
    votingSuggestion,
    clearVotingSuggestions,
    questions,
    clearVotingQuestions
  } = useVotes()
  let targetEl = null

  const {
    personalInformation,
    setPersonalRegister,
    passwordInformation,
    setPasswordRegister,
  } = useRegister()

  useEffect(() => {
    targetEl = modal.current
    visible ? disableBodyScroll(targetEl) : clearAllBodyScrollLocks()
  })

  useEffect(() => {
    if (activeTab === "register") {
      setActiveStep("register")
    }
  }, [activeTab])

  useEffect(() => {
    if (personalInformation && passwordInformation) {
      setLoading(true)
      register({
        ...createRegisterObject(personalInformation, passwordInformation),
        date_of_birth: convertToDateFormat(
          personalInformation.dateOfBirth,
          dateFormat
        ),
        email: personalInformation.email,
      })
        .then(() => {
          setActiveStep("register-success")
          setLoading(false)
        })
        .catch(error => {
          setLoading(false)
          setActiveStep("register-fail")
        })
    }
  }, [personalInformation, passwordInformation])

  const formatQuestionsData = (questions, voterId) => {
    return questions.map(question => {
        return {
          voter_id: voterId,
          question_id: question.question_id,
          answer: question.answer,
        }
    }).filter((e) => e);
  }

  function checkIfVoteNeedsToBeSubmitted(access_token) {
    getMeByAccessToken(access_token).then(response => {
      if (response && access_token) {
        let voteIds = []
        votes.map(vote => {
          voteIds.push(vote.id)
        })
        const obj = {
          voter_id: response.id,
          list_item_ids: voteIds,
          comment: votingSuggestion,
        }
        createQuestions(formatQuestionsData(questions, response.id)).then(() => {
          clearVotingQuestions()
        })
        createVote(obj).then(() => {
          clearVotingSuggestions()
          clearVotingVotes()
          clearLastVoting()
          clearVotingQuestions()
          window.location.reload()
        })
      } else {
        window.location.reload()
      }
    })
  }

  return (
    <Modal
      onClickClose={() => {
        close()
        setActiveStep("login")
      }}
      visible={visible}
    >
      {activeStep === "login" && (
        <Login
          changeTab={() => setActiveStep("register")}
          onSubmitForm={access_token =>
            checkIfVoteNeedsToBeSubmitted(access_token)
          }
          clickForgotPassword={() => setActiveStep("forgot-password")}
        />
      )}

      {activeStep === "forgot-password" && (
        <ForgotPassword
          onSubmitForm={() => setActiveStep("forgot-password-success")}
        />
      )}

      {activeStep === "reset-password" && (
        <ResetPassword onSubmitForm={() => setActiveStep("reset-password")} />
      )}

      {activeStep === "forgot-password-success" && <ForgotPasswordSuccess />}

      {activeStep === "register" && (
        <Register
          changeTab={() => setActiveStep("login")}
          onSubmitForm={values => {
            setPersonalRegister(values)
            setActiveStep("choose-password")
          }}
        />
      )}

      {activeStep === "choose-password" && (
        <ChoosePassword
          loading={loading}
          onSubmitForm={values => {
            setPasswordRegister(values)
          }}
        />
      )}

      {activeStep === "register-success" && <RegisterSuccess />}

      {activeStep === "register-fail" && <RegisterFail />}
    </Modal>
  )
}

export default Auth
