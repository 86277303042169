import { mapKeys, snakeCase, camelCase } from "lodash"

const BASE_URL = process.env.API_BASE_URL_VOTING

function endpoint(endpoint) {
  return `${BASE_URL}${endpoint}`
}

export function transformRequest(request) {
  return mapKeys(request, (value, key) => snakeCase(key))
}

export function transformResponse(response) {
  return mapKeys(response, (value, key) => camelCase(key))
}

export const getVoteListByIdEndpoint = listId =>
  endpoint(`api/survey/list/${listId}`)
export const getVotingParticipationEndpoint = (personId, listId) =>
  endpoint(`api/votes/voted/${personId}/${listId}`)
export const getListTracksEndpoint = (listId, page, limit) =>
  endpoint(`api/survey/list/items/${listId}/${page}/${limit}`)
export const getTopListTracksEndpoint = (listId, page, limit) =>
  endpoint(`api/top/list/items/${listId}/${page}/${limit}`)
export const searchListTracksEndpoint = endpoint(`api/query`)
export const searchTopListTracksEndpoint = endpoint(`api/top/query`)
export const createVoteEndpoint = endpoint("api/votes/create")
export const createQuestionsEndpoint = endpoint("api/answers/create")
