import React from "react"
import PropTypes from "prop-types"
import "./Modal.scss"
import Logo from "../Logo/Logo"
import { logo } from "../../constants/logo"
import Icon from "../Icon/Icon"

class Modal extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    onClickClose: PropTypes.func,
    animating: PropTypes.bool,
    youtube: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { visible, onClickClose, animating, children, youtube } = this.props
    return (
      <>
        {visible && (
          <div
            className={`modal${animating ? " animating" : ""}${
              youtube ? " youtube" : ""
            }`}
          >
            <div className="modal__inner">
              <div className="modal__head">
                {!youtube && (
                    <Logo className="modal__logo" isCompact color={logo.default} />
                )}
                <button className="modal__close" onClick={onClickClose}>
                  <Icon name="close" />
                </button>
              </div>
              <div className="modal__content">{children}</div>
            </div>
            <div onClick={onClickClose} className="modal__backdrop" />
          </div>
        )}
      </>
    )
  }
}

export default Modal
