import { useState } from "react"

export function useRegister() {
  const [personalInformation, setPersonalInformation] = useState(null)
  const [passwordInformation, setPasswordInformation] = useState(null)

  function setPersonalRegister(data) {
    setPersonalInformation(data)
  }

  function setPasswordRegister(data) {
    setPasswordInformation(data)
  }

  return {
    setPersonalRegister,
    setPasswordRegister,
    personalInformation,
    passwordInformation,
  }
}
