import React from "react"
import { useTranslation } from "react-i18next"

function RegisterFail() {
  const { t } = useTranslation()
  return (
    <div className="register-success">
      <div className="form__intro">
        <div className="form__intro--icon"></div>
        <h3 className="form__intro--title">{t("register.titleFail")}</h3>
        <p className="form__intro--body">{t("register.bodyFail")}</p>
      </div>
    </div>
  )
}

export default RegisterFail
