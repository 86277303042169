import React from "react"
import { useField } from "formik"
import Icon from "../Icon/Icon"
import "./SearchField.scss"

export default function SearchField({ label, children, ...props }) {
  const [field] = useField(props)

  return (
    <div className="search--field">
      <label htmlFor="search">
        <Icon className="search--field__icon" name="magnifier" />
      </label>
      <input type="text" id="search" {...field} {...props} />
    </div>
  )
}
