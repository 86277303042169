import { useState } from "react"

export function usePageInformation() {
  const [pageInfo, setPageInfo] = useState(null)

  function setPageInformation(data) {
    setPageInfo(data)
  }
  return {
    pageInfo,
    setPageInformation,
  }
}
