import React from "react"
import "./BtnRounded.scss"
import PropTypes from "prop-types"
import Loader from "../Loader/Loader"

function BtnForms(props) {
  const {
    children,
    icon,
    outline,
    type,
    primary,
    secondary,
    third,
    large,
    _className,
    disabled,
    loading,
  } = props

  const classes = () => {
    let classList = ""
    if (!children) {
      classList += " btn--icon"
    }
    if (outline) {
      classList += " btn--outline"
    }
    if (primary) {
      classList += " btn--primary"
    }
    if (secondary) {
      classList += " btn--secondary"
    }
    if (third) {
      classList += " btn--third"
    }
    if (large) {
      classList += " btn--large"
    }
    return classList
  }

  return (
    <button
      type={type || "submit"}
      className={`btn btn--form btn--rounded${classes()} ${
        _className ? _className : ""
      }`}
      disabled={disabled}
    >
      {icon && <span className="icon">{icon}</span>}
      {children && (
        <span className="content">
          {loading ? (
            <Loader color={secondary ? "#FFA41F" : "#fff"} />
          ) : (
            children
          )}
        </span>
      )}
    </button>
  )
}

BtnForms.propTypes = {
  icon: PropTypes.object,
  outline: PropTypes.bool,
  type: PropTypes.string,
  _className: PropTypes.string,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  third: PropTypes.bool,
  large: PropTypes.bool,
  loading: PropTypes.bool,
}

export default BtnForms
