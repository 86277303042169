import React from "react"
import "./Loader.scss"

function Loader({ color }) {
  return (
    <div className="loading">
      <div style={{ backgroundColor: color || "#fff" }} />
      <div style={{ backgroundColor: color || "#fff" }} />
      <div style={{ backgroundColor: color || "#fff" }} />
      <div style={{ backgroundColor: color || "#fff" }} />
    </div>
  )
}

export default Loader
