import parsePhoneNumber from "libphonenumber-js"

export function checkValidPhone(value) {
  const phoneNumber = parsePhoneNumber(
    value.startsWith("+", 0) ? `${value}` : `+${value}`
  )
  if (phoneNumber) {
    return phoneNumber.isValid()
  }
  return false
}

export function stripCountryCode(value) {
  const phoneNumber = parsePhoneNumber(`+${value}`)
  if (phoneNumber) {
    return phoneNumber.nationalNumber
  }
  return ""
}

export function getFullPhoneNumber(value) {
  const phoneNumber = parsePhoneNumber(`+${value}`)
  if (phoneNumber) {
    return phoneNumber.number
  }
  return ""
}

export function getCountryCode(value) {
  const phoneNumber = parsePhoneNumber(`+${value}`)
  if (phoneNumber) {
    return phoneNumber.country.toLowerCase()
  }
  return "be"
}
