import createPersistedState from "use-persisted-state"
export function useVotes() {
  const [votes, setVotes] = createPersistedState("nostalgie.voting.votes")([])
  const [questions, setQuestions] = createPersistedState("nostalgie.voting.questions")([])
  const [lastVotingUrl, setLastVotingUrl] = createPersistedState(
    "nostalgie.voting.url"
  )(null)
  const [votingSuggestion, setVotingSuggestion] = createPersistedState(
    "nostalgie.voting.suggestion"
  )("")

  function setVotingVotes(data) {
    setVotes(data)
  }

  function clearVotingVotes() {
    setVotes([])
  }

  function setVotingQuestions(data) {
    setQuestions(data)
  }

  function clearVotingQuestions() {
    setQuestions([])
  }

  function setLastVoting(url) {
    setLastVotingUrl(url)
  }

  function clearLastVoting() {
    setLastVoting(null)
  }

  function setVotingSuggestions(data) {
    setVotingSuggestion(data)
  }

  function clearVotingSuggestions() {
    setLastVoting("")
  }

  return {
    setVotingVotes,
    votes,
    clearVotingVotes,
    setLastVoting,
    clearLastVoting,
    setVotingSuggestions,
    clearVotingSuggestions,
    votingSuggestion,
    lastVotingUrl,
    questions,
    setVotingQuestions,
    clearVotingQuestions,
  }
}
