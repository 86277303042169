import React, { useState } from "react"
import PropTypes from "prop-types"
import { Formik } from "formik"
import * as Yup from "yup"
import TextInput from "../Forms/TextInput"
import BtnForms from "../Buttons/BtnForms"
import TabBar from "../Auth/TabBar"
import useForm from "../../hooks/useForm"
import { useGuestApi } from "../../hooks/useApi"
import { profileRoute } from "src/routes/routes"
import { navigate } from "gatsby"
import { useTranslation } from "react-i18next"
import { useLocation } from "@reach/router"

const initialValues = {
  email: "",
  password: "",
}

function Login(props) {
  const [globalError, setGlobalError] = useState(false)
  const { getToken } = useGuestApi()
  const { t } = useTranslation()
  const { changeTab, clickForgotPassword, onSubmitForm } = props
  const history = useLocation()

  const logAnalytics = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "play_nostalgie_login",
    })
  }

  const { submit } = useForm({
    initialValues,
    onSubmit: getToken,
    onSuccess: data => {
      logAnalytics();
      const { access_token } = data.accessToken
      if (history ? history.pathname.includes("/stem/") : false) {
        onSubmitForm(access_token)
        setGlobalError(false)
      } else if(history ? history.pathname.includes("/acties") : false){
        onSubmitForm(access_token)
        setGlobalError(false)
      } else {
        navigate(profileRoute)
        setGlobalError(false)
      }
    },
    onFail: () => setGlobalError(true),
  })

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("validation.emailValid"))
      .required(t("validation.emailRequired")),
    password: Yup.string()
      .min(6, t("validation.passwordValid"))
      .required(t("validation.passwordRequired")),
  })

  return (
    <div className="login">
      <TabBar onClickRegister={changeTab} activeTab={"login"} />
      <Formik
        initialValues={initialValues}
        validationSchema={loginSchema}
        onSubmit={submit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form className="form" onSubmit={handleSubmit}>
            <TextInput
              name="email"
              type="email"
              placeholder={t("forms.email")}
            />
            <TextInput
              name="password"
              type="password"
              placeholder={t("forms.password")}
            />
            <div className="form--info">
              <button
                type="button"
                onClick={clickForgotPassword}
                className="form--link"
              >
                {t("login.forgotPassword")}
              </button>
            </div>
            <BtnForms
              large
              primary
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              {t("login.login")}
            </BtnForms>
            {globalError && (
              <div className="form--error">
                <div className="field--error">{t("login.fail")}</div>
              </div>
            )}
          </form>
        )}
      </Formik>
    </div>
  )
}

Login.propTypes = {
  changeTab: PropTypes.func,
  clickForgotPassword: PropTypes.func,
  onSubmitForm: PropTypes.func,
}

export default Login
