import React from "react"
import { useField } from "formik"
import "./TextInput.scss"

function TextInput({ label, children, className, id, ...props }) {
  const [field, meta] = useField(props)
  return (
    <div
      className={`field${
        meta.touched && meta.error ? " error" : ""
      } ${className}`}
    >
      <label>
        {!!label && <span className="field--label">{label}</span>}
        <input
          className="field--input"
          value={field.value ?? ""}
          name={field.name}
          onChange={field.onChange}
          onBlur={field.onBlur}
          {...props}
        />
      </label>
      {meta.touched && meta.error ? (
        <div className="field--error">{meta.error}</div>
      ) : null}
      {children}
    </div>
  )
}

TextInput.defaultProps = {
  className: "",
}

export default TextInput
