import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock"
import { Formik } from "formik"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import { logo } from "../../constants/logo"
import SearchField from "../Forms/SearchField"
import Icon from "../Icon/Icon"
import Logo from "../Logo/Logo"
import "./SearchModal.scss"

function SearchModal({ visible, handleClose }) {
  const modal = useRef(null)
  let targetEl = null

  useEffect(() => {
    targetEl = modal.current
    visible ? disableBodyScroll(targetEl) : clearAllBodyScrollLocks()
  })

  return (
    <>
      {visible && (
        <div className="search-modal" ref={modal}>
          <button onClick={handleClose} className="modal__close">
           <Icon name="close" />
          </button>
          <div className="search-modal__inner">
            <Logo
              className="search-modal__logo"
              color={logo.default}
              isCompact
            />
            <Formik
              initialValues={{ search: "" }}
              onSubmit={(values, { setSubmitting }) => {
                navigate(`/zoeken?query=${values.search}`)
                setSubmitting(false)
              }}
            >
              {({ handleSubmit, isSubmitting }) => (
                <form className="form" onSubmit={handleSubmit}>
                  <SearchField
                    autoFocus={false}
                    name="search"
                    placeholder={
                      "Zoek artikels, programmas, acties, wedstrijden …."
                    }
                  />
                </form>
              )}
            </Formik>
          </div>
          <div className="search-modal__back" onClick={handleClose} />
        </div>
      )}
    </>
  )
}

SearchModal.propTypes = {
  visible: PropTypes.bool,
  handleClose: PropTypes.func,
}

export default SearchModal
