import { checkValidPhone } from "./Phone"

export function createRegisterObject(personalInformation, passwordInformation) {
  return {
    first_name: personalInformation.firstName,
    last_name: personalInformation.lastName,
    gender: personalInformation.gender,
    terms: personalInformation.terms,
    emails: {
      items: [
        {
          email: personalInformation.email,
        },
      ],
    },
    phones: {
      items: checkValidPhone(personalInformation.phone)
        ? [
            {
              phone: personalInformation.phone,
            },
          ]
        : [],
    },
    addresses: personalInformation.zipCode
      ? {
          items: [
            {
              zip_code: personalInformation.zipCode,
              country: personalInformation.country,
            },
          ],
        }
      : [],
    password: {
      password: passwordInformation.password,
    },
  }
}
