import React, { createContext, useEffect, useState } from "react"
import { get, post, put } from "axios"
import { useOAuthTokens } from "src/hooks/useOAuthTokens"
import {
  formatCarouselData,
  formatHomePageGridData,
  formatSinglePublication,
  formatProgramsData,
  formatVacanciesData,
  formatFaqsData,
  formatCurrentProgram,
  formatGuide,
  formatRangeGuide,
  formatRelatedNewsData,
  formatTopListsPageGridData,
  formatPodcastShowsData,
  formatPodcastSinglesData,
} from "src/helpers/Prepr"
import { formUrlEncoded } from "../helpers/Url"
import { useAccount } from "../hooks/useAccount"
import { usePageInformation } from "../hooks/usePageInformation"
import {
  convertToDateFormat,
  transformRequest,
  getPublicationsEndpoint,
  getCompetitionRuleEndpoint,
  getPublicationByIdEndpoint,
} from "src/services/preprApi"

import {
  getTokenEndpoint,
  requestForgotPasswordEndpoint,
  getMagicLinkTokenEndpoint,
  registerCustomerEndpoint,
  verificationEmailEndpoint,
  personsEndpoint,
  meEndpoint,
} from "src/services/preprCustomerApi"

import { getRadioTracksEndpoint, getPlaylistEndpoint, generateQualifioTokenEndpoint } from "../services/api"

import {
  getVoteListByIdEndpoint,
  getListTracksEndpoint,
  getTopListTracksEndpoint,
  searchListTracksEndpoint,
  searchTopListTracksEndpoint,
  createVoteEndpoint,
  createQuestionsEndpoint,
  getVotingParticipationEndpoint,
} from "../services/votingApi"

import { getGuideRangeEndpoint, getGuideEndpoint } from "../services/legacyApi"

import {
  ACTION_MODEL_ID,
  PROGRAM_MODEL_ID,
  NEWS_MODEL_ID,
  VACANCY_MODEL_ID,
  FAQ_MODEL_ID,
  LIST_MODEL_ID,
  GUIDE_ID,
  TOP_LIST_MODEL_ID,
  PODCAST_SHOW_MODEL_ID,
  PODCAST_SINGLE_MODEL_ID,
  NOSTALGIE_PLUS_HOME_ID,
  NOSTALGIE_PLUS_SUB_ID,
  ADVERT_MODEL_ID,
  STATION_MODEL_ID,
  TOP_LISTS_MODEL_ID,
  HOW_TO_LISTEN_MODEL_ID,
  FAQS_MODEL_ID,
  CONTACT_MODEL_ID,
  COMPETITION_RULES_MODEL_ID,
  VACANCIES_MODEL_ID,
  STATIONS_CAROUSEL_MODEL_ID,
} from "../constants/models"

import { HOMEPAGE_ID, CAROUSEL_ID } from "../constants/channels"
import { getHttpRequestStatusOk } from "../helpers/Api"
import { useTracks } from "../hooks/useTracks"
import { formatStationData, formatStationsData, formatHeader, formatStationsCarouselData } from "../helpers/Prepr"

const GuestApiContext = createContext({})
const { Provider } = GuestApiContext

export function GuestApiProvider({ children }) {
  const { accessToken, setOAuthTokens } = useOAuthTokens()
  const { currentTrack, setTrack } = useTracks()
  const { me } = useAccount()
  const { pageInfo, setPageInformation } = usePageInformation()
  const preprAccessToken = process.env.PREPR_TOKEN
  const [headers] = useState({
    Authorization: `Bearer ${accessToken ? accessToken : preprAccessToken}`,
  })

  async function getMeByAccessToken(accessToken) {
    const headers = { Authorization: `Bearer ${accessToken}` }
    const response = await get(meEndpoint, { headers })
    return response.data
  }

  async function getToken(values) {
    const response = await post(getTokenEndpoint, {
      ttl: 0,
      ...values,
    }, { headers });
    if(response.data?.access_token?.access_token) {
      setOAuthTokens({
        accessToken: response.data.access_token.access_token,
      });
    }

    return response;
  }

  async function getMagicLinkToken(token) {
    const headers = { Authorization: `Bearer ${token}` }
    const response = await post(
      getMagicLinkTokenEndpoint,
      { ttl: 0 },
      { headers }
    )
    return response
  }

  async function forgotPassword({ email }) {
    const obj = {
      email: email,
      email_template: { id: process.env.EMAIL_TEMPLATE_FORGOT_PASSWORD },
      redirect_url: `${process.env.WEBSITE_URL}/account-activatie/${email}`,
    }
    return await post(requestForgotPasswordEndpoint, obj, { headers })
  }

  async function register(values) {
    const response = await post(
      registerCustomerEndpoint,
      transformRequest(values),
      { headers }
    )
    if (getHttpRequestStatusOk(response)) {
      await new Promise(r => setTimeout(r, 1000))
      return await sendVerificationEmail(values)
    }
    return response
  }

  async function addToNewsLetter() {
    const obj = {
      terms: {
        items: [
          {
            id: process.env.NEWSLETTER_ID,
            source: "website",
          },
        ],
      },
    }
    const response = await put(personsEndpoint, obj, { headers })
    return response
  }

  async function sendVerificationEmail(values) {
    const obj = {
      email: values.email,
      email_template: { id: process.env.EMAIL_TEMPLATE_REGISTER },
      redirect_url: `${process.env.WEBSITE_URL}/account-activatie/${values.email}`,
    }

    return await post(verificationEmailEndpoint, obj, { headers })
  }

  async function getCarouselPublications() {
    const url = {
      fields:
        "items{image{cdn_files},image_website_carousel{cdn_files}},model,slug",
      channels: [
        {
          eq: CAROUSEL_ID,
          param: "Id",
        },
      ],
      sort: "-publish_on",
    }

    const response = await get(
      getPublicationsEndpoint(formUrlEncoded(url, false)),
      { headers }
    )
    return formatCarouselData(response.data)
  }

  async function getHomePageGridPublications(pagination) {
    const url = {
      skip: pagination.skip,
      limit: pagination.limit,
      fields:
        "items{image{cdn_files},image_website_block{cdn_files},image_website_detail{cdn_files},layout{storage_files,cdn_files}},model,slug",
      channels: [
        {
          eq: HOMEPAGE_ID,
          param: "Id",
        },
      ],
      sort: '-publish_on'
    };
    try {
      const response = await get(getPublicationsEndpoint(formUrlEncoded(url, false)), { headers });
      return formatHomePageGridData(response.data)
    } catch(e) {
      return e?.response;
    }
  }

  async function getNewsPageGridPublications(pagination) {
    const url = {
      skip: pagination.skip,
      limit: pagination.limit,
      fields:
        "items{image{cdn_files},image_website_block{cdn_files},image_website_detail{cdn_files},layout{storage_files,cdn_files}},model,slug",
      model: {
        id: NEWS_MODEL_ID,
      },
      items: {
        "nl-NL": {
          visible: [
            {
              eq: 1,
            },
          ],
        },
      },
      sort: "-publish_on",
    }

    const response = await get(
      getPublicationsEndpoint(formUrlEncoded(url, false)),
      { headers }
    )
    return formatHomePageGridData(response.data)
  }

  async function getActionsPageGridPublications(pagination) {
    const url = {
      skip: pagination.skip,
      limit: pagination.limit,
      fields:
        "items{image{cdn_files},image_website_block{cdn_files},image_website_detail{cdn_files},layout{storage_files,cdn_files}},model,slug",
      model: {
        id: ACTION_MODEL_ID,
      },
      sort: "-publish_on",
    }
    const response = await get(
      getPublicationsEndpoint(formUrlEncoded(url, false)),
      { headers }
    )
    return formatHomePageGridData(response.data)
  }

  async function getNostalgiePlusHomePublication() {
    const url = {
      fields:
        "items{image{cdn_files},layout{storage_files,cdn_files}},model,slug",
      model: {
        id: NOSTALGIE_PLUS_HOME_ID,
      },
    }
    const response = await get(
      getPublicationsEndpoint(formUrlEncoded(url, false)),
      { headers }
    )
    return formatSinglePublication(response.data)
  }

  async function getContactPublication() {
    const url = {
      fields:
        "items{image{cdn_files},layout{storage_files,cdn_files}},model,slug",
      model: {
        id: CONTACT_MODEL_ID,
      },
    }
    const response = await get(
      getPublicationsEndpoint(formUrlEncoded(url, false)),
      { headers }
    )
    return formatSinglePublication(response.data)
  }

  async function getTopListsPublication() {
    const url = {
      fields:
        "items{image{cdn_files},layout{storage_files,cdn_files}},model,slug",
      model: {
        id: TOP_LISTS_MODEL_ID,
      },
    }
    const response = await get(
      getPublicationsEndpoint(formUrlEncoded(url, false)),
      { headers }
    )
    return formatSinglePublication(response.data)
  }

  async function getCompetitionRulesPublication() {
    const url = {
      fields:
        "items{image{cdn_files},layout{storage_files,cdn_files}},model,slug",
      model: {
        id: COMPETITION_RULES_MODEL_ID,
      },
    }
    const response = await get(
      getPublicationsEndpoint(formUrlEncoded(url, false)),
      { headers }
    )
    return formatSinglePublication(response.data)
  }

  async function getVacanciesOverviewPublication() {
    const url = {
      fields:
        "items{image{cdn_files},footerimage{cdn_files},layout{storage_files,cdn_files}},model,slug",
      model: {
        id: VACANCIES_MODEL_ID,
      },
    }
    const response = await get(
      getPublicationsEndpoint(formUrlEncoded(url, false)),
      { headers }
    )
    return formatSinglePublication(response.data)
  }

  async function getFaqsPublication() {
    const url = {
      fields:
        "items{image{cdn_files},layout{storage_files,cdn_files}},model,slug",
      model: {
        id: FAQS_MODEL_ID,
      },
    }
    const response = await get(
      getPublicationsEndpoint(formUrlEncoded(url, false)),
      { headers }
    )
    return formatSinglePublication(response.data)
  }

  async function getHowToListenPublication() {
    const url = {
      fields:
      "items{image{cdn_files},layout{storage_files,cdn_files},platforms{items{image{cdn_files}}},provinces{items{cities{items}}}},model,slug",
      model: {
        id: HOW_TO_LISTEN_MODEL_ID,
      },
    }
    const response = await get(
      getPublicationsEndpoint(formUrlEncoded(url, false)),
      { headers }
    )
    return formatSinglePublication(response.data)
  }


  async function getNostalgiePlusSubPublication(slug) {
    const url = {
      slug: [
        {
          eq: slug,
        },
      ],
      fields:
        "items{image{cdn_files},layout{storage_files,cdn_files}},model,slug",
      model: {
        id: NOSTALGIE_PLUS_SUB_ID,
      },
    }
    const response = await get(
      getPublicationsEndpoint(formUrlEncoded(url, false)),
      { headers }
    )
    return formatSinglePublication(response.data)
  }

  async function getAdvertPublication() {
    const url = {
      fields:
        "items{image{cdn_files},layout{storage_files,cdn_files},account_contacts{items{image{cdn_files}}}},model,slug",
      model: {
        id: ADVERT_MODEL_ID,
      },
    }
    const response = await get(
      getPublicationsEndpoint(formUrlEncoded(url, false)),
      { headers }
    )
    return formatSinglePublication(response.data)
  }

  async function getTopListsPageGridPublications(pagination) {
    const url = {
      skip: pagination.skip,
      limit: pagination.limit,
      fields:
        "items{image{cdn_files}, hero_detail{cdn_files},tile_large{cdn_files},tile_medium{cdn_files},tile_small{cdn_files},layout{storage_files,cdn_files}},model,slug",
      model: {
        id: TOP_LIST_MODEL_ID,
      },
      sort: "-publish_on",
    }
    const response = await get(
      getPublicationsEndpoint(formUrlEncoded(url, false)),
      { headers }
    )
    return formatTopListsPageGridData(response.data)
  }

  async function getProgramsPublications(pagination) {
    const url = {
      skip: pagination.skip,
      limit: pagination.limit,
      fields:
        "items{image{cdn_files},image_website_block{cdn_files},image_website_detail{cdn_files},layout{storage_files,cdn_files}},model,slug",
      model: {
        id: PROGRAM_MODEL_ID,
      },
      items: {
        "nl-NL": {
          visible: [
            {
              eq: 1,
            },
          ],
        },
      },
      sort: "-publish_on",
    }
    const response = await get(
      getPublicationsEndpoint(formUrlEncoded(url, false)),
      { headers }
    )
    return formatProgramsData(response.data)
  }

  async function getProgramPublicationById(id) {
    const url = {
      fields:
        "items{primary_image{cdn_files},title_image{cdn_files},layout{storage_files,cdn_files}},model,slug",
      model: {
        id: PROGRAM_MODEL_ID,
      },
      status: [
        {
          eq: "Published",
        },
      ],
    }
    const response = await get(
      getPublicationByIdEndpoint(id, formUrlEncoded(url, false)),
      { headers }
    )
    return response?.data
  }

  async function getVacanciesPublications(pagination) {
    const url = {
      skip: pagination.skip,
      limit: pagination.limit,
      fields: "items{cover{cdn_files},{items}},model,slug",
      model: {
        id: VACANCY_MODEL_ID,
      },
      sort: "-publish_on",
    }
    const response = await get(
      getPublicationsEndpoint(formUrlEncoded(url, false)),
      { headers }
    )
    return formatVacanciesData(response.data)
  }

  async function getFaqPublications(pagination) {
    const url = {
      skip: pagination.skip,
      limit: pagination.limit,
      fields: "items,model,slug",
      model: {
        id: FAQ_MODEL_ID,
      },
      sort: "-publish_on",
    }
    const response = await get(
      getPublicationsEndpoint(formUrlEncoded(url, false)),
      { headers }
    )
    return formatFaqsData(response.data)
  }

  async function searchPublications(query, pagination) {
    const url = {
      skip: pagination.skip,
      limit: pagination.limit,
      q: [
        {
          fz: query ? query : null,
        },
      ],
      model: {
        in: [
          NEWS_MODEL_ID,
          ACTION_MODEL_ID,
          PROGRAM_MODEL_ID,
          VACANCY_MODEL_ID,
          LIST_MODEL_ID,
        ],
        param: "id",
      },
      fields:
        "items{image{cdn_files},image_website_block{cdn_files},image_website_detail{cdn_files}},model",
    }
    const response = await get(
      getPublicationsEndpoint(formUrlEncoded(url, false)),
      { headers }
    )
    return formatHomePageGridData(response.data)
  }

  async function getPublicationBySlug(slug) {
    const url = {
      slug: [
        {
          eq: slug,
        },
      ],
      fields:
        "items{image{cdn_files},pdf{cdn_files},hero_detail{cdn_files},tile_large{cdn_files},tile_medium{cdn_files},tile_small{cdn_files},image_website_detail{cdn_files},image_website_block{cdn_files},image_website_carousel{cdn_files},layout{storage_files,cdn_files}},model",
    }
    const response = await get(
      getPublicationsEndpoint(formUrlEncoded(url, false)),
      { headers }
    )
    return formatSinglePublication(response.data)
  }

  async function getRelatedNewsByTags(tags) {
    const url = {
      fields:
        "items{image{cdn_files},image_website_detail{cdn_files},image_website_block{cdn_files},image_website_carousel{cdn_files},layout{storage_files,cdn_files}},model",
      model: {
        id: NEWS_MODEL_ID,
      },
      tags: [
        {
          in: tags,
          param: "Id",
        },
      ],
    }
    const response = await get(
      getPublicationsEndpoint(formUrlEncoded(url, false)),
      { headers }
    )
    return formatRelatedNewsData(response.data)
  }

  async function getCompetitionRuleById(id) {
    const url = {
      fields:
        "items{image{cdn_files},image_mobile_detail{cdn_files},layout{storage_files,cdn_files}},model",
      status: [
        {
          eq: "Published",
        },
      ],
    }
    const response = await get(
      getCompetitionRuleEndpoint(id, formUrlEncoded(url, false)),
      { headers }
    )
    return response?.data
  }

  async function getRadioTracks(radioId) {
    try {
    const response = await get(getRadioTracksEndpoint(radioId), { headers })
    return response.data.data
    } catch(e) {
      return [];
    }
  }

  async function getPlaylist(radioId, pagination) {
    const response = await get(getPlaylistEndpoint(radioId, pagination), {
      headers,
    })
    return response.data
  }

  async function getQualifioToken(obj) {
    try {
      const response = await post(generateQualifioTokenEndpoint, obj, { headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}})
      return response
    } catch(e) {
      return e?.response;
    }
  }

  async function getVotePublicationBySlug(slug) {
    const url = {
      slug: [
        {
          eq: slug,
        },
      ],
      fields:
        "items{image{cdn_files},image_website_detail{cdn_files},tracks{items}},containers,model",
      model: {
        id: LIST_MODEL_ID,
      },
    }
    const response = await get(
      getPublicationsEndpoint(formUrlEncoded(url, false)),
      { headers }
    )
    return formatSinglePublication(response.data)
  }

  async function getVoteListById(listId) {
    const response = await get(getVoteListByIdEndpoint(listId))
    return response.data
  }

  async function checkVotingParticipation(personId, listId) {
    const response = await get(getVotingParticipationEndpoint(personId, listId))
    return response.data.voted
  }

  async function getListTracks(listId, pagination) {
    const response = await get(
      getListTracksEndpoint(listId, pagination.page, pagination.limit)
    )
    return response.data
  }

  async function getTopListTracks(listId, pagination) {
    const response = await get(
      getTopListTracksEndpoint(listId, pagination.page, pagination.limit)
    )
    return response.data
  }

  async function searchListTracks(obj) {
    const response = await post(searchListTracksEndpoint, obj)
    return response.data
  }

  async function searchTopListTracks(obj) {
    const response = await post(searchTopListTracksEndpoint, obj)
    return response.data
  }

  async function createVote(obj) {
    try {
      const response = await post(createVoteEndpoint, obj)
      return response.data
    } catch(e) {
      return e;
    }
  }

  async function createQuestions(obj) {
    try {
      const response = await post(createQuestionsEndpoint, obj)
      return response.data
    } catch(e) {
      return e;
    }
  }

  async function getCurrentProgram() {
    const response = await get(getGuideEndpoint(GUIDE_ID), { headers })
    return formatCurrentProgram(response.data)
  }

  async function getProgramGuide() {
    const response = await get(getGuideEndpoint(GUIDE_ID), { headers })
    return formatGuide(response.data)
  }

  async function getProgramGuideRange(from, until) {
    const response = await get(getGuideRangeEndpoint(GUIDE_ID, from, until), {
      headers,
    })
    return formatRangeGuide(response.data)
  }

  async function getPodcastShowPublications(pagination) {
    const url = {
      skip: pagination.skip,
      limit: pagination.limit,
      fields:
        "items{image{cdn_files},image_website_block{cdn_files},image_website_detail{cdn_files},layout{storage_files,cdn_files}},model,slug",
      model: {
        id: PODCAST_SHOW_MODEL_ID,
      },
      sort: "-publish_on",
    }
    const response = await get(
      getPublicationsEndpoint(formUrlEncoded(url, false)),
      { headers }
    )
    return formatPodcastShowsData(response.data)
  }

  async function getPodcastShowPublicationBySlug(slug) {
    const url = {
      slug: [
        {
          eq: slug,
        },
      ],
      fields:
        "items{image{cdn_files},image_website_detail{cdn_files},tracks{items},apple_url,spotify_url},containers,model",
      model: {
        id: PODCAST_SHOW_MODEL_ID,
      },
    }
    const response = await get(
      getPublicationsEndpoint(formUrlEncoded(url, false)),
      { headers }
    )
    const podcastShow = formatSinglePublication(response.data)
    const singlePodcastUrl = {
      limit: 250,
      model: {
        id: PODCAST_SINGLE_MODEL_ID,
      },
      fields:
        "items{image{cdn_files},image_website_detail{cdn_files},tracks{items},backtracks_embed,spotify_url,apple_url},containers,model",
      items: {
        "nl-NL": {
          podcast_show: [
            {
              "eq": podcastShow.id
            }
          ],
        },
      },
    }
    const singlePodcastsResponse = await get(
      getPublicationsEndpoint(formUrlEncoded(singlePodcastUrl, false)),
      { headers }
    )
    podcastShow.podcasts = formatPodcastSinglesData(
      singlePodcastsResponse.data
    )?.items
    return podcastShow
  }

  async function getPodcastSinglePublicationBySlug(slug) {
    const url = {
      slug: [
        {
          eq: slug,
        },
      ],
      fields:
        "items{image{cdn_files},image_website_detail{cdn_files},tracks{items},backtracks_embed,spotify_url,apple_url},containers,model",
      model: {
        id: PODCAST_SINGLE_MODEL_ID,
      },
    }
    const response = await get(
      getPublicationsEndpoint(formUrlEncoded(url, false)),
      { headers }
    )
    return formatSinglePublication(response.data)
  }

  async function getMainHeader() {
    try {
      const program = await getCurrentProgram();
      if(!program?.referenceId){
        return formatHeader(null);
      }

      const programPublication = await getProgramPublicationById(program.referenceId);
      return formatHeader(programPublication);

    } catch(e) {
      return formatHeader(null);
    }
  }

  async function getStationsPublications() {
    const url = {
      fields:
      "items{image_website{cdn_files},image_mobile{cdn_files}},model",
      model: {
        id: STATION_MODEL_ID,
      },
    }
    const response = await get(
      getPublicationsEndpoint(formUrlEncoded(url, false)),
      { headers }
    )
    return formatStationsData(response.data)
  }

  async function getStationsCarouselPublication() {
    const url = {
      fields:
      "items{image_website{cdn_files},image_mobile{cdn_files},stations{items{image_website{cdn_files},image_mobile{cdn_files}},model}},model",
      model: {
        id: STATIONS_CAROUSEL_MODEL_ID,
      },
    }
    const response = await get(
      getPublicationsEndpoint(formUrlEncoded(url, false)),
      { headers }
    )
    return formatStationsCarouselData(response.data)
  }

  async function getStationPublicationBySlug(slug) {
    const url = {
      slug: [
        {
          eq: slug,
        },
      ],
      fields:
      "items{image_website{cdn_files},image_mobile{cdn_files}},model",
      model: {
        id: STATION_MODEL_ID,
      },
    }
    const response = await get(
      getPublicationsEndpoint(formUrlEncoded(url, false)),
      { headers }
    )
    return formatStationData(response.data)
  }

  return (
    <Provider
      value={{
        me,
        getCarouselPublications,
        getHomePageGridPublications,
        getPublicationBySlug,
        getRadioTracks,
        getNewsPageGridPublications,
        getActionsPageGridPublications,
        getProgramsPublications,
        getVacanciesPublications,
        getFaqPublications,
        getCurrentProgram,
        getProgramGuide,
        getToken,
        register,
        convertToDateFormat,
        pageInfo,
        setPageInformation,
        searchPublications,
        getVotePublicationBySlug,
        getListTracks,
        forgotPassword,
        accessToken,
        setTrack,
        currentTrack,
        getPlaylist,
        getMagicLinkToken,
        addToNewsLetter,
        getProgramGuideRange,
        getRelatedNewsByTags,
        getVoteListById,
        searchListTracks,
        createVote,
        getMeByAccessToken,
        checkVotingParticipation,
        getTopListsPageGridPublications,
        getTopListTracks,
        searchTopListTracks,
        getCompetitionRuleById,
        getPodcastShowPublications,
        getPodcastShowPublicationBySlug,
        getPodcastSinglePublicationBySlug,
        getQualifioToken,
        createQuestions,
        getNostalgiePlusHomePublication,
        getNostalgiePlusSubPublication,
        getAdvertPublication,
        getMainHeader,
        getStationsPublications,
        getStationsCarouselPublication,
        getStationPublicationBySlug,
        getContactPublication,
        getCompetitionRulesPublication,
        getVacanciesOverviewPublication,
        getFaqsPublication,
        getHowToListenPublication,
        getTopListsPublication,
      }}
    >
      {children}
    </Provider>
  )
}

export default GuestApiContext
